import '../../styles/faqModal.css';
import { useState, useEffect } from "react";
import { Autocomplete, Dialog } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTextField } from "../../utils/CustomFaqStyles"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from "../../../../../components/LoadingButton";
import { FAQ_STATUS, NOTIFICATION_TYPES } from "../../../../../constants/common";
import { invalidFranchiseId } from "../../../../../constants/faq";
import { createFaq, updateFaqById } from "../../../../../services/faqService"
import RenderInput from '../../../../../components/servicetitan/RenderInput';
import { handleFaqGetKeywords } from '../../../../../state-services/faq/faq';

export default function FaqModel({ onClose, open, franchise, franchiseName, brand, refresh, faq, showNotification, keywords, isKeywordsLoading, isKeywordsError }) {
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [isEnabled, setIsEnabled] = useState(FAQ_STATUS.enabled)
    const [isInitStage, setIsInitStage] = useState(true)
    const [loading, setLoading] = useState(false)
    const [isFaqKeywordsLoading, setIsFaqKeywordsLoading] = useState(false)
    const [isFaqKeywordsError, setIsFaqKeywordsError] = useState(false)

    useEffect(() => {
        if (faq?.id) {
            handleFaqGetKeywords(faq?.id, (keywords) => setSelectedKeywords(keywords?.map?.((k) => k?.keyword)), setIsFaqKeywordsLoading, setIsFaqKeywordsError, faq?.id)
        } else {
            setSelectedKeywords([])
        }
    }, [faq?.id])

    useEffect(() => {
        setQuestion(faq?.question ? faq.question : "")
        setAnswer(faq?.answer ? faq.answer : "")
        setIsInitStage(true)
        setIsEnabled(faq?.is_active === FAQ_STATUS.disabled ? FAQ_STATUS.disabled : FAQ_STATUS.enabled)
    }, [faq]);

    useEffect(() => {
        if (faq?.id) {
            setQuestion(faq?.question ? faq.question : "")
            setAnswer(faq?.answer ? faq.answer : "")
            setIsEnabled(faq?.is_active === FAQ_STATUS.disabled ? FAQ_STATUS.disabled : FAQ_STATUS.enabled)
        } else {
            setQuestion("")
            setAnswer("")
            setIsEnabled(FAQ_STATUS.enabled)
            setSelectedKeywords([])
        }
    }, [open]);

    const handleClose = () => {
        return onClose();
    }

    const saveOnClick = async (e) => {
        try {
            e.preventDefault()
            setIsInitStage(false)
            if (question.trim() !== "" && answer.trim() !== "") {
                setLoading(true)
                let newFaq = null
                newFaq = {
                    brand_id: brand.id,
                    franchise_id: franchise ? franchise.id : invalidFranchiseId,
                    question: document.getElementById("new-q").value.trim(),
                    answer: document.getElementById("new-a").value.trim(),
                    keywords: selectedKeywords,
                    is_template_faq: 0,
                    is_active: isEnabled
                }
                await createFaq(newFaq)
                setQuestion("")
                setAnswer("")
                setIsInitStage(FAQ_STATUS.enabled)
                handleClose()
                showNotification({ message: "FAQ has been added successfully", type: NOTIFICATION_TYPES.SUCCESS })
                refresh()
            }
        } catch (error) {
            showNotification({ message: "Unable to add FAQ at this time", type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setLoading(false)
        }
    }

    const updateOnclick = async (e) => {
        try {
            e.preventDefault()
            setIsInitStage(false)
            let body = {}
            if (question.trim() !== "" && answer.trim() !== "") {
                setLoading(true)
                body = {
                    franchise_id: faq?.franchise_id,
                    brand_id: faq?.brand_id,
                    question: document.getElementById("new-q").value.trim(),
                    answer: document.getElementById("new-a").value.trim(),
                    keywords: selectedKeywords,
                    is_active: isEnabled
                }
                await updateFaqById(faq?.id, body)
                setIsInitStage(true)
                handleClose()
                showNotification({ message: "FAQ has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
                refresh()
            }
        } catch (error) {
            showNotification({ message: "Unable to update FAQ at this time", type: NOTIFICATION_TYPES.ERROR })
        } finally {
            setLoading(false)
        }
    }

    const statusOnChange = (e, faq) => {
        setIsEnabled(e.target.checked ? FAQ_STATUS.enabled : FAQ_STATUS.disabled)
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className="dialog">
                <IconButton onClick={handleClose} className="faq-icon-btn" size={"small"} aria-label="delete">
                    <CloseIcon className="close-icon" />
                </IconButton>
                <h3 className="dialog-title faq-modal-title"> {faq?.id ? "Update FAQ" : "Create FAQ"} </h3>
                <small className="name-tag">
                    <div className='tag-title' ><strong>Brand : </strong> {brand?.brand_name} </div>
                    {((!faq?.id && franchise) || (faq?.franchise_id && faq?.franchise_id !== -1)) ? <div className='tag-title' style={{ marginLeft: "20px" }}><strong>Franchise : </strong> {franchiseName ? franchiseName : franchise?.franchise_name}</div> : <></>}
                </small>
                <div className="form-container">
                    <form onSubmit={faq?.id ? updateOnclick : saveOnClick} >
                        <div className="form-field-wrapper">
                            <CustomTextField
                                inputProps={{ "data-test": "faq_modal_question_field" }}
                                type="text"
                                fullWidth
                                label="Question"
                                maxRows={3}
                                multiline
                                size="small"
                                margin="none"
                                variant="outlined"
                                id="new-q"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)} error={!isInitStage && question.trim() === "" ? true : false}
                                helperText={!isInitStage && question.trim() === "" ? "Question is required" : ""}
                            />
                            <CustomTextField

                                type="text"
                                multiline
                                maxRows={3}
                                label="Answer"
                                fullWidth
                                size="small"
                                margin="normal"
                                variant="outlined"
                                id="new-a"
                                onChange={(e) => setAnswer(e.target.value)} value={answer} error={!isInitStage && answer.trim() === "" ? true : false}
                                helperText={!isInitStage && answer.trim() === "" ? "Answer is required" : ""}
                            />
                            <Autocomplete
                                sx={{ width: "100%", marginTop: "0.5em" }}
                                size="small"
                                multiple={true}
                                noOptionsText={(isKeywordsError || isFaqKeywordsError) ? "Temporary error" : "No keywords"}
                                loading={(isKeywordsLoading || isFaqKeywordsLoading)}
                                options={keywords?.map?.((v) => v?.keyword)}
                                getOptionLabel={(keyword) => keyword}
                                renderOption={(props, keyword) => <RenderInput {...props} key={keyword} content={keyword} data-test={`faq_modal_keyword_${keyword}`} />}
                                value={selectedKeywords}
                                onChange={(_, keywords) => {
                                    setSelectedKeywords(keywords)
                                }}
                                renderInput={(params) => {
                                    let trimmedValue = params.inputProps.value?.trimStart();
                                    return (
                                        <CustomTextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                value: trimmedValue,
                                                "data-test": "faq_modal_keywords_field"
                                            }}
                                            label="Keywords"
                                        />
                                    )
                                }
                                }
                            />
                            <div className="checkbox-wrapper">
                                <FormControlLabel control={<Checkbox inputProps={{ "data-test": "faq_modal_is_active_checkbox" }} size="small" onChange={(e) => statusOnChange(e, faq)} checked={isEnabled === FAQ_STATUS.enabled ? true : false} />} label="Is Active" />
                            </div>
                            <div className="loading-btn-wrapper">
                                <LoadingButton data-test="faq_model_save" type="submit" size="small" id="lol" variant="contained" className="btn-primary" loading={loading} disabled={loading}>
                                    {faq?.id ? "Update" : "Add New"}
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
}